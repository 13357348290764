<template>
    <div>
        <multiselect
            v-model="selected"
            :class="validateError != '' ? 'box-border-color' : ''"
            :clear-on-select="true"
            :close-on-select="true"
            :deselect-label="''"
            :multiple="false"
            :options="options"
            :placeholder="$t('select')"
            :select-label="''"
            :selected-label="''"
            label="text"
            track-by="value"
            @input="handleInput($event)"
        >
            <span slot="noOptions">{{ $t("no_options") }}</span>
            <span slot="noResult">{{ $t("no_result") }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
// Helpers
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import ScholarshipTypeService from "@/services/ScholarshipTypeService";

// Other
import qs from "qs";

export default {
    props: {
        type: {
            type: String,
            default: null,
        },
        value: {
            default: null,
        },
        validateError: {
            type: String,
            default: "",
        },
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options)
        }
    },
    data() {
        return {
            selected: null,
            options: [],
            items: null,
        }
    },
    created() {
        this.getOptions();
    },
    methods: {
        handleInput,
        setSelected,
        getOptions() {
            const key = 'scholarship_types';
            let local = this.getWithExpiry(key);
            if (local) {
                this.setOptions(local);
                this.selected = this.setSelected(this.value, this.options, this.multiple)
                return;
            }
            let params = {limit: -1, sort: "id"};
            if (this.type != null) {
                params.filter.type = this.type;
            }
            const config = {
                params: params,
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            ScholarshipTypeService.getAll(config)
                .then((response) => {
                    const data = response.data.data;
                    this.setWithExpiry(key, data);
                    this.setOptions(data);
                })
                .then(() => {
                    this.selected = this.setSelected(this.value, this.options)
                })
        },
        getItem(id) {
            if (this.items == null) {
                return null;
            }
            let found = null;
            this.items.forEach((item) => {
                if (item.id === id) {
                    found = item;
                }
            });
            return found;
        },
        setOptions(data){
            this.items = data;
            data.map((item) => {
                this.options.push({
                    value: item.id,
                    text: item.name
                })
            })
        }
    }
}
</script>
