import FinanceApi from '@/services/FinanceApi';

const get = async (id) => {
    return FinanceApi.get('/scholarships/' + id);
}

const store = async (formData) => {
    if (typeof formData.id != 'undefined' && formData.id > 0) {
        return FinanceApi.put('/scholarships/' + formData.id, formData);
    }
    return FinanceApi.post('/scholarships', formData);
}

const approve = async (id, data) => {
    return FinanceApi.put('/scholarships/' + id + '/approve', data);
}

const deleteScholarship = async (id) => {
    return FinanceApi.delete('/scholarships/' + id);
}

export default {
    get,
    store,
    approve,
    deleteScholarship
}
